var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog",class:_vm.isDarkOverlay ? 'bg-overlay' : 'bg-defaultOverlay'},[_c('div',{class:_vm.containerClass},[_c('div',{staticClass:"dialog-container-default relative p-6 mx-6 sm:mx-0"},[(_vm.isShowCloseBtn)?_c('CloseIcon',{staticClass:"close-dialog-icon cursor-pointer animation-focus-default w-6 h-6 absolute top-4 right-4 sm:top-7 sm:right-7",on:{"click":_vm.cancel}}):_vm._e(),_c('div',{staticClass:"flex flex-col",class:{
          'items-center': _vm.scheme === _vm.SCHEMES.primary,
        }},[(_vm.icon)?_c('div',{staticClass:"bg-orange-bgr rounded-full flex justify-center items-center w-12 h-12 mb-5 cursor-pointer animation-focus-default"},[(_vm.icon === 'delete')?_c('DeleteIcon',{staticClass:"w-4 h-4"}):_vm._e(),(_vm.icon === 'comment')?_c('CommentIcon',{staticClass:"icon w-4 h-4"}):_vm._e(),(_vm.icon === 'CTA')?_c('CTAIcon',{staticClass:"w-6 h-6"}):_vm._e()],1):_vm._e(),(_vm.header)?_c('h5',{staticClass:"font-semibold mb-2",class:{
            'text-gray-900 text-md lg:text-2xl font-normal': _vm.scheme === _vm.SCHEMES.secondary,
            'truncate w-[90%] lg:w-[46%]': _vm.isTruncate,
          }},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),(_vm.text)?_c('div',{staticClass:"mb-6",class:{
            'text-gray-700 text-base pt-4': _vm.scheme === _vm.SCHEMES.secondary,
            'text-gray-500 text-sm text-center': _vm.scheme === _vm.SCHEMES.primary,
          }},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c('div',[_vm._t("default")],2),(_vm.isShowControlButtons)?_c('div',{staticClass:"controls w-full flex flex-wrap flex-col-reverse md:flex-nowrap md:flex-row gap-1",class:_vm.actionWrapperClass ? _vm.actionWrapperClass : 'justify-center'},[_c('Button',{staticClass:"cancel-btn min-w-[130px] h-10",class:_vm.actionButtonClass,attrs:{"type":"text","compact":""},on:{"onClick":_vm.cancel}},[_c('div',{staticClass:"text-sm text-gray-600 font-semibold"},[_vm._v("Cancel")])]),_c('Button',{staticClass:"save-btn min-w-[130px] h-10",class:_vm.actionButtonClass,attrs:{"type":"primary","compact":"","primaryFullSize":false},on:{"onClick":_vm.apply}},[_c('div',{staticClass:"text-sm text-white font-semibold"},[_vm._v(_vm._s(_vm.applyBtnText))])])],1):_vm._e(),(_vm.showInfoButton)?_c('div',{staticClass:"controls w-full flex justify-center"},[_c('Button',{staticClass:"save-btn w-[200px] h-10",class:_vm.actionButtonClass,attrs:{"type":"primary","compact":"","primaryFullSize":false},on:{"onClick":_vm.apply}},[_c('div',{staticClass:"text-sm text-white font-semibold"},[_vm._v(_vm._s(_vm.infoBtnText))])])],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }